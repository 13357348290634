<template>
  <v-container>
    <v-row>
      <v-breadcrumbs :items="bread" large></v-breadcrumbs>
    </v-row>
    <div>
      <h1>Importar Desarrollo del Crédito</h1>
    </div>
    <v-row>
      <v-col> Subir archivo XLXS </v-col>
    </v-row>
    <v-row>
      <v-col cols="8">
        <v-file-input v-model="archivo" label="Archivo"></v-file-input>
      </v-col>
      <v-col cols="4">
        <v-btn
          @click="uploadFile"
          color="primary"
          :disabled="!archivo"
          :loading="loadingCarga"
          >Importar</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-simple-table dense fixed-header>
            <thead>
              <tr>
                <th
                  class="text-left"
                  v-for="(h, idx) in table.headers"
                  :key="idx"
                >
                  {{ h }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(d, idx) in table.data" :key="idx">
                <td>
                  {{ d.A }}
                </td>
                <td>
                  {{ d.B }}
                </td>
                <td>
                  {{ d.C }}
                </td>
                <td>
                  {{ d.D }}
                </td>
                <td>
                  {{ d.E }}
                </td>
                <td>
                  {{ d.F }}
                </td>
                <td>
                  {{ d.G }}
                </td>
                <td>
                  {{ d.H }}
                </td>
                <td>
                  {{ d.I }}
                </td>
                <td>
                  {{ d.J }}
                </td>
                <td>
                  {{ d.K }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from 'axios';
import { API_URL } from '@/common/config';

export default {
  data: () => ({
    table: {
      headers: [],
      data: [],
    },
    loadingCarga: false,
    archivo: [],
    success: false,
    successMensaje: '',
    excel: [],
    bread: [
      {
        text: 'Mendoza Fiduciaria',
        disabled: false,
        href: '/',
      },
      {
        text: 'Legajos',
        disabled: true,
        href: '/',
      },
      {
        text: 'Importar Desarrollo del Crédito',
        disabled: true,
        href: '/',
      },
    ],
  }),
  name: 'ImportarLegajo',
  methods: {
    async uploadFile() {
      this.loadingCarga = true;
      const url = `${API_URL}api/legajos/importarCuadroMarcha`;
      const header = {
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      const data = new FormData();
      data.append('adjunto', this.archivo);
      await axios
        .post(url, data, header)
        .then((r) => {
          if (r.data.status === 'success') {
            this.success = true;
            this.successMensaje = 'Excel leido con exito';
            this.table.headers = r.data.headers;
            this.table.data = r.data.data;
          } else {
            this.error = true;
            this.successError = r.data.message;
          }
          this.loadingCarga = false;
        })
        .catch((e) => {
          console.error(e);
          this.loadingCarga = false;
          this.$swal.fire(
            'Error',
            'Ocurrió un error al importar los Cuadros de Marcha. Por favor re-intentar.',
            'error'
          );
        });
    },
  },
};
</script>
